@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';
@import './odometer-theme-plaza.scss';
@import './fonts.scss';
@import '../fonts/fonts.css';
@import './toast.scss';
@import './variables.scss';
@import 'tooltips/tooltip';
@import 'tooltips/tooltip-base';
@import 'typography';
@import 'overrides';
@import 'overrides/ngx-material-timepicker';
@import 'overrides/mat-dialog';
@import 'overrides/mat-menu';
@import 'overrides/mat-slide-toggle';
@import 'overrides/ng9-odometer';
@import 'overrides/ngx-tooltip';
@import 'overrides/mat_zesty_modal_standard';
@import 'overrides/bs_gray-tooltip';
@import 'text/text_styles';
@import 'mixins/font-props';
@import 'helpers/helpers';
// NEW DESIGN SYSTEM
@import 'design-system/buttons';
@import 'design-system/checkbox';
@import 'design-system/icons';

// [appCounter] directive
.appCounter {
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
	height: 1em;
}
.appCounter > span {
	z-index: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	transition: transform 1.5s ease;
	transform: translateY(0);
	line-height: 1;
}
.appCounter > span span {
	flex: 0 0 100%;
	height: 100%;
}

/* BASE COLORS */
:root {
	--accent-color: #5d78ff;
	--cv-danger-color: #ff4d6b;
}

body {
	background: $zesty-background;
}

.hidden-if-empty {
	&:empty {
		display: none;
	}
}

.mat-date-range-input-start-wrapper {
	flex-shrink: 0;
}

/* header */
.navbar-toggler .d-lg-none {
	display: none !important;
}

img.navbar-brand-full {
	max-width: 180px;
	max-height: 50px;
}

// Fix logo image size
.app-header .navbar-brand > img {
	object-fit: contain;
	width: 100%;
}

@media (min-width: 599px) {
	.app-header .navbar-brand {
		width: 200px;
		background: #001f38;
	}
}

.app-header {
	border: 0px;
}

.app-header .navbar-toggler:hover {
	color: #fff !important;
}

.navbar-toggler-icon:hover {
	color: #fff !important;
}

.hide {
	display: none;
}

/* sidebar */
@media screen and (min-width: 992px) {
	.sidebar-fixed .sidebar {
		width: 200px;
	}
}

@media screen and (max-width: 992px) {
	.sidebar {
		position: fixed;
		height: 100vh;
	}
}

@media (max-width: 578px) {
	.app-header .navbar-brand {
		left: 13.5%;
		margin-left: 0;
	}

	.app-header .ml-auto {
		display: none;
	}
}

@media screen and (max-width: 578px) and (orientation: portrait) {
	.sidebar {
		width: 100%;
	}

	html:not([dir='rtl']) .sidebar {
		margin-left: -100%;
	}

	.sidebar .sidebar-nav,
	.sidebar .nav {
		width: 100%;
	}

	.sidebar .nav-title {
		font-size: 90%;
	}

	.sidebar .nav-link {
		font-size: 17px;
	}
}

main::before {
	position: fixed !important;
}

.sidebar {
	background: #001f38;
}

.sidebar .nav {
	padding-top: 55px;
}

.sidebar .nav-link {
	padding-right: 7px;
	@include font-props(false, null, null, null, #a2a3b7);
}

.sidebar .nav-link .badge {
	margin-top: 4px;
}

.sidebar .nav-link.active .nav-icon {
	color: #c6ceff;
}

.sidebar .nav-link .nav-icon {
	margin-right: 4px;
}

.sidebar .nav-link.active:hover .nav-icon {
	color: #fff;
}

.sidebar .nav-link:hover {
	background-color: #21366b !important;
}

::selection {
	background: #00466b;
	/* WebKit/Blink Browsers */
	color: #fff;
}

::-moz-selection {
	background: #00466b;
	/* Gecko Browsers */
	color: #fff;
}

.valueinput-shadow {
	border: 0;
	transition: box-shadow 0.15s ease;
	border-radius: 0.375rem;
	box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.breadcrumb {
	border: 0px;
	box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
}

.blur {
	filter: blur(5px);
}

.transp-input {
	border: 0px;
	background: transparent;
	font-family: Courier, inherit;
}

.transp-input:focus {
	outline-width: 0;
}

.transp-input[type][disabled] {
	background: transparent;
}

.bg-disqualified {
	opacity: 0.5;
}

awscognito-angular2-app {
	height: 100vh;
}

.app-footer {
	-ms-flex: 0 0 35px;
	flex: 0 0 35px;
}

.bg-cv-blue {
	background: #023359;
	color: #fff;
}

.bg-light-gray {
	background-color: #f7f8fa;
}

.w-100 {
	width: 100%;
}

.text-sm-80 {
	font-size: 80%;
}

.text-sm-90 {
	font-size: 90%;
}

.text-black {
	color: #000 !important;
}

.text-blue {
	color: #023359 !important;
}

.text-light-blue {
	color: #20a8d8;
}

.text-bold {
	font-weight: bold;
}

.text-upper {
	text-transform: uppercase;
}

.table td {
	vertical-align: middle;
}

.table-cursor-pointer > tbody > tr > td {
	cursor: pointer;
}

.modal-fullscreen-limited {
	width: 90% !important;
	max-width: 1400px !important;
	min-height: 90vh !important;
	height: 90%;
}

.modal-fullscreen {
	max-width: 90% !important;
	min-height: 90vh !important;
	height: 90%;
}

.modal-fullscreen .modal-content {
	height: auto;
	min-height: 100%;
	border-radius: 0;
	border: 0px;
}

.modal-fullscreen-limited .modal-content {
	height: auto;
	min-height: 100%;
	border-radius: 0px;
	border: 0px;
}

.modal-fullscreen-limited-height {
	width: 90% !important;
	max-width: 1400px !important;
}

.modal-fullscreen-limited-height .modal-content {
	border-radius: 0px;
	border: 0px;
}

.dt-dropdowns .datatable-body {
	overflow-x: visible !important;
}

.tabs_no_b_border .tab-content {
	border-bottom: 0px !important;
	border-left: 0px !important;
	border-right: 0px !important;
}

.progress-bar {
	background-color: #023359;
}

.bg_filpblue {
	background: #e8edf2;
}

.b-0 {
	border: 0px;
}

.cube-grid-spinner {
	width: 60px !important;
	height: 60px !important;
}

.cube1,
.cube2,
.cube3,
.cube4,
.cube5,
.cube6,
.cube7,
.cube8,
.cube9 {
	background: #e8edf2 !important;
}

.required-field {
	transform: translateY(calc(-50% + 15px));
	opacity: 0.3;
	position: absolute;
	font-size: 9px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	text-transform: uppercase;
	color: #023359;
	right: 1rem;
}

.cursor-help {
	cursor: help;
}

.cursor-link {
	cursor: pointer;
}

.cursor-normal {
	cursor: default !important;
}

.height-lim-400px {
	max-height: 400px;
	overflow-y: auto;
}

.html-tooltip + .tooltip {
	opacity: 1;
}

.html-tooltip + .tooltip > .tooltip-inner {
	background-color: #fff;
	min-width: 450px;
	color: #000;
	box-shadow: 0 10px 21px -5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	-o-transition: box-shadow 0.3s;
	transition: box-shadow 0.3s;
}

.white-btn {
	font-size: 13px;
	font-weight: 300;
	background: #fff;
}

.white-btn:hover {
	box-shadow: 0 10px 21px -5px rgba(0, 0, 0, 0.15);
	background: #f9f9fc;
	transition: box-shadow 0.5s;
}

.btn-dt {
	padding: 0.13rem 0.4rem;
	font-size: 0.7rem;
	line-height: 1.4;
	margin-top: -8px;
}

/* btn */
.btn-icon {
	border: 0;
	background: none;
	outline: none !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 35px;
	width: 35px;
	background-color: #f7f8fa;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	cursor: pointer;
	margin: 0;
	border-radius: 0;
	border-radius: 4px;
}

.btn-icon i {
	font-size: 1.1rem;
}

.btn-icon.btn-icon--sm {
	height: 26px;
	width: 26px;
}

.btn-icon.btn-icon--sm i {
	font-size: 0.8rem;
}

.btn-icon.btn-icon--md {
	height: 30px;
	width: 30px;
}

.btn-icon.btn-icon--md i {
	font-size: 1rem;
}

.btn-icon.btn-icon--light {
	background-color: transparent;
}

.btn-icon i {
	color: #8e96b8;
}

.btn-icon g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
	fill: #8e96b8;
}

.btn-icon:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
}

.btn-icon.btn-icon--active,
.btn-icon:hover {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background-color: #ebedf2;
}

.btn-icon.btn-icon--active.btn-icon--light,
.btn-icon:hover.btn-icon--light {
	background-color: transparent;
}

.btn-icon.btn-icon--active i,
.btn-icon:hover i {
	color: #5d78ff;
}

.btn-icon.btn-icon--active g [fill],
.btn-icon:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
	fill: #5d78ff;
}

.btn-icon.btn-icon--active:hover g [fill],
.btn-icon:hover:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
}

.btn-icon.btn-icon--back {
	background-color: transparent;
}

.btn-icon.btn-icon--back i {
	color: #8e96b8;
	font-size: 1.5rem;
}

.btn-icon.btn-icon--back g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
	fill: #8e96b8;
}

.btn-icon.btn-icon--back:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
}

.btn-icon.btn-icon--back svg {
	height: 32px;
	width: 32px;
}

.btn-icon.btn-icon--back:hover {
	background-color: transparent;
}

.btn-icon.btn-icon--back:hover i {
	color: #5d78ff;
}

.btn-icon.btn-icon--back:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
	fill: #5d78ff;
}

.btn-icon.btn-icon--back:hover:hover g [fill] {
	-webkit-transition: fill 0.3s ease;
	transition: fill 0.3s ease;
}

/* .ngx-datatable.material {
    overflow: -webkit-paged-y;
} */
.internal_header {
	background-color: #fff;
	color: #1c356a;
	border-bottom: 1px solid #eff0f6;
}

/* .internal_header .nav-link {
    color: #fff !important;
}
.internal_header .navbar-nav .active>.nav-link, .navbar-nav .active>.nav-link:focus, .navbar-nav .active>.nav-link:hover, .navbar-nav .nav-link.active, .navbar-nav .nav-link.active:focus, .navbar-nav .nav-link.active:hover, .navbar-nav .nav-link.open, .navbar-nav .nav-link.open:focus, .navbar-nav .nav-link.open:hover, .navbar-nav .open>.nav-link, .navbar-nav .open>.nav-link:focus, .navbar-nav .open>.nav-link:hover {
    color: #fff;
} */
/* KPIs widgets */
.main-kpi {
	background: #fff;
	box-shadow: 0 10px 21px -5px rgba(0, 0, 0, 0.15);
	padding: 10px;
	color: #000;
	border-radius: 10px;
	-o-transition: box-shadow 0.3s;
	transition: box-shadow 0.3s;
}

.main-kpi .h4 {
	min-height: 25px;
}

.main-kpi svg {
	opacity: 0.6;
}

.main-kpi svg:hover {
	opacity: 1;
}

.kpi_title {
	@include font-props(false, 25, true, 500, #2f504f);
}

.kpi_val {
	color: #2f504f;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}

/* tabs */
.nav-tabs .nav-link.active {
	border-top: 3px solid #e07700 !important;
	background-color: #fff;
	border-bottom-color: transparent;
}

.nav-tabs .nav-link:hover {
	background-color: #fff;
	border-bottom-color: transparent;
}

.nav-tabs.grey-bg .nav-link.active {
	border-top: 3px solid #e07700 !important;
	background-color: #f9fcfc;
	border-bottom-color: transparent;
}

.nav-tabs.grey-bg .nav-link:hover {
	background-color: #f9fcfc;
	border-bottom-color: transparent;
}

.nav-tabs.grey-bg-dark .nav-link.active {
	border-top: 3px solid #e07700 !important;
	background-color: #f0f3f5;
	border-bottom-color: transparent;
}

.nav-tabs.grey-bg-dark .nav-link:hover {
	background-color: #f0f3f5;
	border-bottom-color: transparent;
}

/* selectable.js */
.ui-lasso {
	display: none;
}

/* dropdown */
.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 15rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	white-space: nowrap;
	background: #f0f1f7;
	@include font-props(true, 0.875, false, null, #6c757d);
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.dropdown-toggle:after {
	display: none;
}

.form-dropdown.dropdown .form-control {
	border: solid 1px #9fabc3;
}

.form-dropdown.dropdown .form-control.is-invalid {
	border-color: #ff4d6b;
}

.dropdown-menu {
	border: 0;
	box-shadow: 0 1px 4px rgba(0, 0, 51, 0.2);
	margin: 0;
	padding: 0 0 5px;
	z-index: 999999;
}

.scroll-gradient {
	background-image: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%);
	border-radius: 5px;
	content: '';
	height: 30px;
	margin-top: -29px;
	pointer-events: none;
	position: relative;
}

.dropdown-header {
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	padding: 10px 20px;
}

.dropdown-divider {
	border-color: #d3d9e6;
	margin: 0 0 5px;
}

.dropdown-item {
	cursor: pointer;
	padding: 10px 20px;
	@include font-props(false, 12, true, 300);
}

.dropdown-item:hover {
	background-color: rgba(211, 217, 230, 0.3);
}

.dropdown-item.active {
	background-color: transparent;
	color: #06f;
}

.dropdown-item.is-selected .dropdown-item-text {
	color: #06f;
}

.dropdown-item ~ .dropdown-divider {
	margin-top: 5px;
}

/* panel restyle */
.panel {
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
	padding: 20px;
}

.panel-heading {
	padding: 15px 20px;
	border-bottom: 1px solid transparent;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.panel-heading > .dropdown .dropdown-toggle {
	color: inherit;
}

.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 15px;
	color: inherit;
}

.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
	color: inherit;
}

.panel-footer {
	padding: 7px 20px;
	background-color: #fcfcfc;
	border-top: 1px solid #ddd;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.list-group-item {
	box-shadow: 0 10px 21px -5px rgba (0, 0, 0, 0.15) !important;
	border-radius: 10px;
	transition: box-shadow 0.3s !important;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
	margin-bottom: 0;
}

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
	border-width: 1px 0;
	border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
	border-top: 0;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
	border-bottom: 0;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child {
	border-top-width: 0;
}

.list-group + .panel-footer {
	border-top-width: 0;
}

.panel > .panel-collapse > .table,
.panel > .table,
.panel > .table-responsive > .table {
	margin-bottom: 0;
}

.panel > .panel-collapse > .table caption,
.panel > .table caption,
.panel > .table-responsive > .table caption {
	padding-left: 20px;
	padding-right: 20px;
}

.panel > .table-responsive:first-child > .table:first-child,
.panel > .table:first-child {
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child {
	border-top-left-radius: 2px;
}

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
	border-top-right-radius: 2px;
}

.panel > .table-responsive:last-child > .table:last-child,
.panel > .table:last-child {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
	border-bottom-left-radius: 2px;
}

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
	border-bottom-right-radius: 2px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
	border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child td,
.panel > .table > tbody:first-child > tr:first-child th {
	border-top: 0;
}

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
	border: 0;
}

.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
	border-left: 0;
}

.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
	border-right: 0;
}

.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
	border-bottom: 0;
}

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
	border-bottom: 0;
}

.panel > .table-responsive {
	border: 0;
	margin-bottom: 0;
}

.panel-group {
	margin-bottom: 20px;
}

.panel-group .panel {
	margin-bottom: 0;
	border-radius: 3px;
}

.panel-group .panel + .panel {
	margin-top: 5px;
}

.panel-group .panel-heading {
	border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
	border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
	border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
	border-bottom: 1px solid #ddd;
}

.panel-default {
	border-color: #ddd;
}

.panel-default > .panel-heading {
	color: #333;
	background-color: #fcfcfc;
	border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
	color: #fcfcfc;
	background-color: #333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #ddd;
}

.panel-primary {
	border-color: #2196f3;
}

.panel-primary > .panel-heading {
	color: #fff;
	background-color: #2196f3;
	border-color: #2196f3;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #2196f3;
}

.panel-primary > .panel-heading .badge {
	color: #2196f3;
	background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #2196f3;
}

.panel-success {
	border-color: #4caf50;
}

.panel-success > .panel-heading {
	color: #fff;
	background-color: #4caf50;
	border-color: #4caf50;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #4caf50;
}

.panel-success > .panel-heading .badge {
	color: #4caf50;
	background-color: #fff;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #4caf50;
}

.panel-info {
	border-color: #00bcd4;
}

.panel-info > .panel-heading {
	color: #fff;
	background-color: #00bcd4;
	border-color: #00bcd4;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #00bcd4;
}

.panel-info > .panel-heading .badge {
	color: #00bcd4;
	background-color: #fff;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #00bcd4;
}

.panel-warning {
	border-color: #ff5722;
}

.panel-warning > .panel-heading {
	color: #fff;
	background-color: #ff5722;
	border-color: #ff5722;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #ff5722;
}

.panel-warning > .panel-heading .badge {
	color: #ff5722;
	background-color: #fff;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #ff5722;
}

.panel-danger {
	border-color: #f44336;
}

.panel-danger > .panel-heading {
	color: #fff;
	background-color: #f44336;
	border-color: #f44336;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #f44336;
}

.panel-danger > .panel-heading .badge {
	color: #f44336;
	background-color: #fff;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #f44336;
}

/* card restyle */
.card {
	margin-bottom: 1.25rem;
	box-shadow: 0 10px 21px -5px rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
	/* border-radius: 0px; */
	transition: box-shadow 0.3s;
	border: 0px;
}

.card-header {
	border-radius: 0.25rem 0.25rem 0px 0px !important;
}

.card .content-divider > span,
.tab-content-bordered .content-divider > span {
	background-color: #fff;
}

.card > .table:first-child caption + thead tr:first-child td,
.card > .table:first-child caption + thead tr:first-child th,
.card > .table:first-child colgroup + thead tr:first-child td,
.card > .table:first-child colgroup + thead tr:first-child th,
.card > .table:first-child thead:first-child tr:first-child td,
.card > .table:first-child thead:first-child tr:first-child th,
.card > [class*='table-responsive']:first-child > .table:first-child caption + thead tr:first-child td,
.card > [class*='table-responsive']:first-child > .table:first-child caption + thead tr:first-child th,
.card > [class*='table-responsive']:first-child > .table:first-child colgroup + thead tr:first-child td,
.card > [class*='table-responsive']:first-child > .table:first-child colgroup + thead tr:first-child th,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child td,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child th {
	border-top-width: 0;
}

.card.card-table {
	border-width: 1px;
	border-color: #ddd;
}

.card > .table caption,
.card > [class*='table-responsive'] > .table caption {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.card > .table:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child {
	border-top-left-radius: 0.125rem;
	border-top-right-radius: 0.125rem;
}

.card > .table:first-child tbody:first-child tr:first-child,
.card > .table:first-child thead:first-child tr:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child tbody:first-child tr:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child {
	border-top-left-radius: 0.125rem;
	border-top-right-radius: 0.125rem;
}

.card > .table:first-child tbody:first-child tr:first-child td:first-child,
.card > .table:first-child tbody:first-child tr:first-child th:first-child,
.card > .table:first-child thead:first-child tr:first-child td:first-child,
.card > .table:first-child thead:first-child tr:first-child th:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child tbody:first-child tr:first-child td:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child tbody:first-child tr:first-child th:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child td:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child th:first-child {
	border-top-left-radius: 0.125rem;
}

.card > .table:first-child tbody:first-child tr:first-child td:last-child,
.card > .table:first-child tbody:first-child tr:first-child th:last-child,
.card > .table:first-child thead:first-child tr:first-child td:last-child,
.card > .table:first-child thead:first-child tr:first-child th:last-child,
.card > [class*='table-responsive']:first-child > .table:first-child tbody:first-child tr:first-child td:last-child,
.card > [class*='table-responsive']:first-child > .table:first-child tbody:first-child tr:first-child th:last-child,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child td:last-child,
.card > [class*='table-responsive']:first-child > .table:first-child thead:first-child tr:first-child th:last-child {
	border-top-right-radius: 0.125rem;
}

.card > .table:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child {
	border-bottom-right-radius: 0.125rem;
	border-bottom-left-radius: 0.125rem;
}

.card > .table:last-child tbody:last-child tr:last-child,
.card > .table:last-child tfoot:last-child tr:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tbody:last-child tr:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tfoot:last-child tr:last-child {
	border-bottom-left-radius: 0.125rem;
	border-bottom-right-radius: 0.125rem;
}

.card > .table:last-child tbody:last-child tr:last-child td:first-child,
.card > .table:last-child tbody:last-child tr:last-child th:first-child,
.card > .table:last-child tfoot:last-child tr:last-child td:first-child,
.card > .table:last-child tfoot:last-child tr:last-child th:first-child,
.card > [class*='table-responsive']:last-child > .table:last-child tbody:last-child tr:last-child td:first-child,
.card > [class*='table-responsive']:last-child > .table:last-child tbody:last-child tr:last-child th:first-child,
.card > [class*='table-responsive']:last-child > .table:last-child tfoot:last-child tr:last-child td:first-child,
.card > [class*='table-responsive']:last-child > .table:last-child tfoot:last-child tr:last-child th:first-child {
	border-bottom-left-radius: 0.125rem;
}

.card > .table:last-child tbody:last-child tr:last-child td:last-child,
.card > .table:last-child tbody:last-child tr:last-child th:last-child,
.card > .table:last-child tfoot:last-child tr:last-child td:last-child,
.card > .table:last-child tfoot:last-child tr:last-child th:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tbody:last-child tr:last-child td:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tbody:last-child tr:last-child th:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tfoot:last-child tr:last-child td:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child tfoot:last-child tr:last-child th:last-child {
	border-bottom-right-radius: 0.125rem;
}

.card > .table-bordered,
.card > [class*='table-responsive'] > .table-bordered {
	border: 0;
}

.card > .table-bordered tbody td:first-child,
.card > .table-bordered tbody th:first-child,
.card > .table-bordered tfoot td:first-child,
.card > .table-bordered tfoot th:first-child,
.card > .table-bordered thead td:first-child,
.card > .table-bordered thead th:first-child,
.card > [class*='table-responsive'] > .table-bordered tbody td:first-child,
.card > [class*='table-responsive'] > .table-bordered tbody th:first-child,
.card > [class*='table-responsive'] > .table-bordered tfoot td:first-child,
.card > [class*='table-responsive'] > .table-bordered tfoot th:first-child,
.card > [class*='table-responsive'] > .table-bordered thead td:first-child,
.card > [class*='table-responsive'] > .table-bordered thead th:first-child {
	border-left: 0;
}

.card > .table-bordered tbody td:last-child,
.card > .table-bordered tbody th:last-child,
.card > .table-bordered tfoot td:last-child,
.card > .table-bordered tfoot th:last-child,
.card > .table-bordered thead td:last-child,
.card > .table-bordered thead th:last-child,
.card > [class*='table-responsive'] > .table-bordered tbody td:last-child,
.card > [class*='table-responsive'] > .table-bordered tbody th:last-child,
.card > [class*='table-responsive'] > .table-bordered tfoot td:last-child,
.card > [class*='table-responsive'] > .table-bordered tfoot th:last-child,
.card > [class*='table-responsive'] > .table-bordered thead td:last-child,
.card > [class*='table-responsive'] > .table-bordered thead th:last-child {
	border-right: 0;
}

.card-body + .table,
.card-body + div[class*='table-responsive'],
.card-header:not([class*='bg-']) + .table,
.card-header:not([class*='bg-']) + div[class*='table-responsive'],
.navbar-light + .table,
.navbar-light + div[class*='table-responsive'],
.table + .card-body,
div[class*='table-responsive'] + .card-body {
	border-top: 1px solid #ddd;
}

.table tbody:first-child tr:first-child td,
.table tbody:first-child tr:first-child th,
div[class*='table-responsive'] > .table tbody:first-child tr:first-child td,
div[class*='table-responsive'] > .table tbody:first-child tr:first-child th {
	border-top: 0;
}

.card-footer + .table,
.card-footer + [class*='table-responsive'] {
	border-top: 1px solid #ddd;
}

.card.fixed-top {
	overflow: auto;
	max-height: 100%;
}

.card-body:not(.card) + .card-body:not(.card) {
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-title {
	position: relative;
}

.card-header .card-title {
	margin-bottom: 0;
}

.card[class*='bg-']:not(.bg-light):not(.bg-white):not(.bg-transparent) .card-header {
	border-bottom-color: rgba(255, 255, 255, 0.1);
}

.card-header:not([class*='bg-']):not([class*='alpha-']) {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	border-bottom-width: 0;
}

.card-header:not([class*='bg-']):not([class*='alpha-']) + * > .card-body:first-child,
.card-header:not([class*='bg-']):not([class*='alpha-']) + .card-body {
	padding-top: 0;
}

.card-footer:first-child,
.card-header + * > .card-footer:first-child,
.card-header + .card-footer {
	border-top: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer.border-bottom-1 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header-tabs {
	margin-bottom: 0;
}

.card-header-tabs .nav-link {
	border-top-width: 0;
}

.card-body .card-img {
	border-radius: 0.1875rem;
}

.card-img-actions {
	position: relative;
}

.card-img-actions:focus .card-img-actions-overlay,
.card-img-actions:hover .card-img-actions-overlay {
	opacity: 1;
	visibility: visible;
}

.card-img-actions-overlay {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.75);
	color: #fff;
	opacity: 0;
	visibility: hidden;
	transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
	.card-img-actions-overlay {
		transition: none;
	}
}

.card-group-control .card-title > a {
	display: inline-block;
}

.card-group-control .card-title > a:before {
	content: '\ed5b';
	font-family: icomoon;
	position: absolute;
	top: 50%;
	margin-top: -0.5rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.card-group-control .card-title > a.collapsed:before {
	content: '\ed5a';
}

.card-group-control-left .card-title > a {
	padding-left: 1.625rem;
}

.card-group-control-left .card-title > a:before {
	left: 0;
}

.card-group-control-right .card-title > a {
	padding-right: 1.625rem;
}

.card-group-control-right .card-title > a:before {
	right: 0;
}

/* datatable */
.datatable-scroll {
	width: 100% !important;
}

.ngx-datatable,
.info {
	text-align: left;
	width: 75%;
	margin: 0 auto;
}

.info {
	box-sizing: border-box;
	padding: 0 1em;
	border: solid 1px #ccc;
	background: white;
	font-size: 0.8em;
	margin-bottom: 1em;
}

.ngx-datatable.scroll-vertical {
	height: 70vh;
}

.selected-column {
	background: #fff;
	-o-transition: box-shadow 0.3s;
	transition: box-shadow 0.3s;
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

.datatable-search input[type='text'] {
	font-size: 14px;
	display: block;
	background: transparent;
	width: 70%;
	border: none;
	border-bottom: 1px solid #0233592b;
	color: #0233592b;
}

.datatable-search input:focus {
	outline: none;
	border-bottom: 1px solid #023359;
	color: #023359;
}

/* when row is selected */
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
	background-color: #f0f3ff !important;
	color: #fff !important;
}

/* when row is mouse hover */
.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
	background: #f3f5fb !important;
}

.dt-grey-header .datatable-header-cell {
	background: #f9fcfc;
}

.selection-cell .datatable-body-cell.active {
	background: #023359 !important;
	color: #fff !important;
}

.selected-column {
	margin-right: 20px;
	text-align: left;
	left: auto !important;
	right: -20px !important;
	width: 250px;
	font-size: inherit;
}

.card-header-action span {
	font-size: 10px;
	padding-left: 6px;
	vertical-align: text-bottom;
}

.selected-column h4 {
	text-align: center;
	margin: 10px 0 0 0;
	padding: 0;
}

.selected-column ul {
	list-style: none;
	margin: 15px 0px 0px 19px;
	padding: 0px;
	padding-bottom: 10px;
	text-align: left;
}

.selected-column li {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

.selected-column input[type='checkbox'],
input[type='radio'] {
	margin-right: 5px;
}

datatable-progress .container {
	max-width: 100%;
}

.ngx-datatable.material {
	box-shadow: 0 0px rgba(0, 0, 0, 0);
	background: #eef5f652;
}

.ngx-datatable.material table {
	font-size: 13px;
}

.datatable-row-detail {
	background-color: #e7ecf1 !important;
}

.datatable-icon-right {
	cursor: pointer;
}

.datatable-icon-down {
	cursor: pointer;
}

.disclaimer {
	color: #242a31;
	background-color: #ffffff;
	border-radius: 3px;
	border-left: 3px solid #f47c24;
	transition: border ease 200ms;
	box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
	text-decoration: none;
}

/* checkbox */
.form-radio,
.form-checkbox {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	position: relative;
	color: #666;
	top: 10px;
	height: 30px;
	width: 30px;
	border: 0;
	border-radius: 50px;
	cursor: pointer;
	margin-right: 7px;
	outline: none;
}

.form-checkbox {
	border-radius: 3px;
	background: #fff;
	border: 1px solid #cad1d736;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-radio:checked::before,
.form-checkbox:checked::before {
	position: absolute;
	font: 13px/1 'Open Sans', sans-serif;
	left: 11px;
	top: 7px;
	content: '\02143';
	transform: rotate(40deg);
}

.form-radio:hover,
.form-checkbox:hover {
	background-color: #f7f7f7;
}

.form-radio:checked,
.form-checkbox:checked {
	background-color: #f1f1f1;
}

label {
	font: 300 16px/1.7 'Open Sans', sans-serif;
	color: #666;
	cursor: pointer;
}

input[type][disabled] + label {
	color: #999;
	cursor: default;
}

.severity-High {
	background: #ff020030 !important;
}

.severity-Medium {
	background: #ffbf004f !important;
}

/*pure table*/
.table100 {
	background-color: #fff;
}

table {
	width: 100%;
}

th,
td {
	font-weight: unset;
	padding-right: 10px;
}

.pure-table {
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
	border: 1px solid #cbcbcb;
}

.pure-table caption {
	color: #000;
	font: italic 85%/1 arial, sans-serif;
	padding: 1em 0;
	text-align: center;
}

.pure-table td,
.pure-table th {
	border-left: 1px solid #cbcbcb;
	border-width: 0 0 0 1px;
	font-size: inherit;
	margin: 0;
	overflow: visible;
	padding: 0.5em 1em;
}

.pure-table td:first-child,
.pure-table th:first-child {
	border-left-width: 0;
}

.pure-table thead {
	background-color: #00466b2b;
	color: #000;
	text-align: left;
	vertical-align: bottom;
}

.pure-table td {
	background-color: transparent;
}

.pure-table-odd td {
	background-color: #f2f2f2;
}

.pure-table-striped tr:nth-child(2n-1) td {
	background-color: #f2f2f2;
}

/* BORDERED TABLES */
.pure-table-bordered td {
	border-bottom: 1px solid #cbcbcb;
}

.pure-table-bordered tbody > tr:last-child > td {
	border-bottom-width: 0;
}

/* HORIZONTAL BORDERED TABLES */
.pure-table-horizontal td,
.pure-table-horizontal th {
	border-width: 0 0 1px 0;
	border-bottom: 1px solid #cbcbcb;
}

.pure-table-horizontal tbody > tr:last-child > td {
	border-bottom-width: 0;
}

.styled_input {
	background-color: #fff !important;
	border-radius: 21px;
	height: 34px;
	line-height: 42px;
	border: 0px;
	color: #59667f !important;
	padding: 0 14px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	width: 100%;
}

/* table style */
.b-typo dl,
.u-text-dl,
.u-text-p {
	margin-bottom: 1em;
}

.b-typo dl dd,
.b-typo dl dt,
.u-text-dl dd,
.u-text-dl dt {
	display: block;
}

.b-typo dl dd:last-child,
.b-typo dl dt:last-child,
.u-text-dl dd:last-child,
.u-text-dl dt:last-child {
	margin: 0;
}

.b-typo dl dt,
.u-text-dl dt {
	font-weight: 400;
}

.b-typo dl dd,
.u-text-dl dd {
	margin-bottom: 1em;
	font-weight: 700;
}

.b-typo table {
	width: 100%;
	table-layout: fixed;
}

.b-typo table tbody tr {
	border-top: 1px solid #d2d6da;
}

.b-typo table td,
.b-typo table th {
	padding: 12px 8px;
	vertical-align: top;
}

.b-typo table th {
	background: #f7f8f8;
}

.b-typo table p:last-child {
	margin-bottom: 0;
}

@media (max-width: 599px) {
	.b-typo {
		font-size: 12px;
	}

	.b-typo small {
		font-size: 10px;
	}
}

.b-scrollable {
	overflow-x: auto;
}

.floating-footer {
	z-index: -15;
}

/* loading pie */
.loading-overlay .loading-pi {
	min-height: 58px;
	position: relative;
	width: auto;
	margin: 0 auto 12px auto;
}

.loading-overlay .oao-pi-loader {
	font-size: 10px;
	margin: 50px auto;
	text-indent: -9999em;
	width: 8em;
	height: 8em;
	border-radius: 50%;
	background: #00466b;
	background: -webkit-linear-gradient(left, #00466b 10%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(10%, #00466b),
		color-stop(42%, rgba(255, 255, 255, 0))
	);
	background: linear-gradient(to right, #00466b 10%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: oao-pi-load3 1s infinite linear;
	animation: oao-pi-load3 1s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	overflow: hidden;
}

.loading-overlay .oao-pi-loader:before {
	width: 50%;
	height: 50%;
	background: #00466b;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}

.loading-overlay .oao-pi-loader:after {
	background: #edf0f3;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

@-webkit-keyframes oao-pi-load3 {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes oao-pi-load3 {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* circles */
@media (max-width: 991px) {
	.bg-object {
		left: -300px;
	}

	.bg-object.subpage-intro {
		right: -300px;
	}

	.bg-circle.medium.payroll-2 {
		top: 180px;
		right: -90px;
	}

	.bg-circle.medium.cta-2 {
		top: 327px;
	}

	.bg-circle.medium.modern-1 {
		left: -100px;
	}

	.bg-circle.medium.faq-2 {
		right: -20px;
	}

	.bg-circle.medium.all-features-2 {
		left: -73px;
	}

	.bg-circle.medium.platform-2 {
		top: -112px;
	}

	.bg-circle.medium.pricing-2 {
		left: -80px;
	}

	.bg-circle.small.faq-3 {
		top: 135px;
		right: -65px;
	}

	.bg-circle.small.platform-3 {
		left: -68px;
		top: -155px;
	}

	.bg-circle.cta-1 {
		left: -161px;
	}

	.bg-circle.large-light {
		left: -475px;
	}

	.bg-circle.large-light.mission {
		right: -306px;
	}

	.bg-circle.all-features {
		left: -167px;
	}

	.bg-circle.platform-intro-1 {
		left: -105px;
		top: -42px;
	}

	.bg-circle.platform-intro-1.pricing-1 {
		left: -20px;
	}

	.bg-circle.subpage-top-1 {
		left: -197px;
	}
}

@media (max-width: 767px) {
	.bg-circle.medium.pricing-2,
	.bg-circle.platform-intro-1.pricing-1,
	.bg-circle.small.pricing-3 {
		display: none;
	}

	.bg-circle.medium.company-2 {
		right: -90px;
	}

	.bg-circle.medium.subpage-top-2 {
		left: -30px;
		top: 71px;
	}

	.bg-circle.medium.subpage-bottom-2 {
		top: 16px;
	}

	.bg-circle.small.subpage-top-3 {
		left: -56px;
		top: 139px;
	}

	.bg-circle.subpage-bottom-1 {
		top: -120px;
	}

	.bg-circle.security-bottom-right {
		right: -110px;
	}
}

@media (max-width: 479px) {
	.bg-object {
		width: 500px;
		height: 500px;
	}

	.bg-object.subpage-intro {
		top: -75px;
	}

	.bg-circle.medium {
		left: -30px;
	}

	.bg-circle.medium.payroll-2 {
		top: 150px;
		right: -50px;
		display: none;
	}

	.bg-circle.medium.cta-2 {
		left: -40px;
	}

	.bg-circle.medium.platform-2 {
		left: -40px;
		top: -72px;
	}

	.bg-circle.medium.subpage-top-2 {
		left: -50px;
	}

	.bg-circle.payroll-1 {
		right: -138px;
		display: none;
	}

	.bg-circle.large-light.platform-large-left {
		left: -532px;
	}

	.bg-circle.platform-intro-1 {
		left: -125px;
		top: 8px;
	}

	.bg-circle.subpage-top-1 {
		left: -237px;
	}

	.bg-circle.subpage-bottom-1 {
		right: -103px;
	}
}

@media screen and (max-width: 767px) {
	.w-hidden-main,
	.w-hidden-medium {
		display: inherit !important;
	}

	.w-hidden-small {
		display: none !important;
	}

	.w-container .w-row,
	.w-row {
		margin-left: 0;
		margin-right: 0;
	}

	.w-col {
		width: 100%;
		left: auto;
		right: auto;
	}

	.w-col-small-1 {
		width: 8.33333333%;
	}

	.w-col-small-2 {
		width: 16.66666667%;
	}

	.w-col-small-3 {
		width: 25%;
	}

	.w-col-small-4 {
		width: 33.33333333%;
	}

	.w-col-small-5 {
		width: 41.66666667%;
	}

	.w-col-small-6 {
		width: 50%;
	}

	.w-col-small-7 {
		width: 58.33333333%;
	}

	.w-col-small-8 {
		width: 66.66666667%;
	}

	.w-col-small-9 {
		width: 75%;
	}

	.w-col-small-10 {
		width: 83.33333333%;
	}

	.w-col-small-11 {
		width: 91.66666667%;
	}

	.w-col-small-12 {
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	.w-container {
		max-width: none;
	}

	.w-hidden-main,
	.w-hidden-medium,
	.w-hidden-small {
		display: inherit !important;
	}

	.w-hidden-tiny {
		display: none !important;
	}

	.w-col {
		width: 100%;
	}

	.w-col-tiny-1 {
		width: 8.33333333%;
	}

	.w-col-tiny-2 {
		width: 16.66666667%;
	}

	.w-col-tiny-3 {
		width: 25%;
	}

	.w-col-tiny-4 {
		width: 33.33333333%;
	}

	.w-col-tiny-5 {
		width: 41.66666667%;
	}

	.w-col-tiny-6 {
		width: 50%;
	}

	.w-col-tiny-7 {
		width: 58.33333333%;
	}

	.w-col-tiny-8 {
		width: 66.66666667%;
	}

	.w-col-tiny-9 {
		width: 75%;
	}

	.w-col-tiny-10 {
		width: 83.33333333%;
	}

	.w-col-tiny-11 {
		width: 91.66666667%;
	}

	.w-col-tiny-12 {
		width: 100%;
	}
}

.bg-object {
	position: absolute;
	left: -120px;
	top: -180px;
	z-index: 1;
	width: 640px;
	height: 600px;
	border-radius: 30% 70% 30% 100%;
	background-color: #ecf3ff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#ecf3ff), to(#fff));
	background-image: linear-gradient(180deg, #ecf3ff, #fff);
}

.bg-circle {
	position: absolute;
	left: -130px;
	top: 510px;
	z-index: 5;
	width: 120px;
	height: 120px;
	border: 20px solid #216ef4;
	border-radius: 100%;
	background-color: transparent;
}

.bg-circle.medium {
	left: -10px;
	top: 470px;
	width: 40px;
	height: 40px;
	border-width: 10px;
}

.bg-circle.medium.payroll-2 {
	left: auto;
	top: 205px;
	right: 20px;
}

.bg-circle.medium.cta-2 {
	left: -10px;
	top: 357px;
}

.bg-circle.medium.modern-1 {
	left: -60px;
	top: 275px;
	z-index: 6;
	width: 60px;
	height: 60px;
}

.bg-circle.medium.company-2 {
	left: auto;
	top: 457px;
	right: 20px;
}

.bg-circle.medium.faq-2 {
	left: auto;
	top: 187px;
	right: 63px;
}

.bg-circle.medium.how-it-works-2 {
	left: auto;
	top: 314px;
	right: 20px;
}

.bg-circle.medium.all-features-2 {
	left: -93px;
	top: 217px;
}

.bg-circle.medium.all-features-2.right-veresion {
	left: auto;
	top: 217px;
	right: -93px;
}

.bg-circle.medium.platform-2 {
	left: -9px;
	top: -120px;
	border-color: #52d2bc;
}

.bg-circle.medium.different-2 {
	left: -115px;
	top: -170px;
	border-color: #52d2bc;
}

.bg-circle.medium.subpage-top-2 {
	top: 101px;
	border-color: #e8ab2d;
}

.bg-circle.medium.subpage-bottom-2 {
	left: auto;
	top: 6px;
	right: -36px;
	border-color: #52d2bc;
}

.bg-circle.medium.pricing-2 {
	left: -50px;
	top: 100px;
}

.bg-circle.small {
	left: -25px;
	top: 405px;
	width: 15px;
	height: 15px;
	border-width: 0;
	background-color: #216ef4;
}

.bg-circle.small.payroll-3 {
	left: auto;
	top: 140px;
	right: -55px;
}

.bg-circle.small.company-3 {
	left: auto;
	top: 405px;
	right: -25px;
}

.bg-circle.small.faq-3 {
	left: auto;
	top: 85px;
	right: 45px;
}

.bg-circle.small.how-it-works-3 {
	left: auto;
	top: 265px;
	right: -25px;
}

.bg-circle.small.all-features-3 {
	left: -195px;
	top: 109px;
}

.bg-circle.small.all-features-3.right-version {
	left: auto;
	top: 109px;
	right: -195px;
}

.bg-circle.small.platform-3 {
	left: -71px;
	top: -165px;
}

.bg-circle.small.about-3 {
	left: auto;
	top: auto;
	right: -101px;
	bottom: 354px;
}

.bg-circle.small.security-3 {
	left: auto;
	top: auto;
	right: 28px;
	bottom: 258px;
	background-color: #52d2bc;
}

.bg-circle.small.different-3 {
	left: -146px;
	top: -90px;
	background-color: #e8ab2d;
}

.bg-circle.small.subpage-top-3 {
	left: 78px;
	top: 180px;
	background-color: #52d2bc;
}

.bg-circle.small.subpage-bottom-3 {
	left: auto;
	top: -176px;
	right: -25px;
	background-color: #e8ab2d;
}

.bg-circle.small.pricing-3 {
	top: -30px;
	z-index: 50;
	background-color: #52d2bc;
}

.bg-circle.payroll-1 {
	left: auto;
	top: 253px;
	right: -100px;
}

.bg-circle.payroll-1.bottom-left {
	left: 0;
	top: -200px;
	right: auto;
	bottom: auto;
}

.bg-circle.cta-1 {
	left: -241px;
	top: 327px;
	width: 200px;
	height: 200px;
	border-width: 40px;
	border-color: #f6f8fc;
}

.bg-circle.large {
	left: auto;
	top: -150px;
	right: -50px;
	z-index: 4;
	width: 300px;
	height: 300px;
	border-width: 40px;
	border-color: #f5f7fa;
}

.bg-circle.large.how-it-works {
	top: 177px;
}

.bg-circle.large.how-it-works.submit-bulk {
	top: -185px;
}

.bg-circle.large.about-bottom {
	top: auto;
	right: -125px;
	bottom: -68px;
	border-width: 41px;
	border-color: rgba(7, 38, 93, 0.05);
}

.bg-circle.large.bottom-left {
	left: -210px;
	top: auto;
	right: auto;
	bottom: -180px;
}

.bg-circle.large-light {
	left: -555px;
	top: 340px;
	z-index: 4;
	width: 600px;
	height: 600px;
	border-width: 130px;
	border-color: rgba(7, 38, 93, 0.04);
	opacity: 1;
}

.bg-circle.large-light.platform-large-left {
	left: -452px;
	top: -376px;
	width: 700px;
	height: 700px;
	border-width: 160px;
	border-color: #102f66;
}

.bg-circle.large-light.mission {
	left: auto;
	top: 65px;
	right: -136px;
	z-index: 2;
	border-color: hsla(0, 0%, 100%, 0.65);
	opacity: 0.05;
}

.bg-circle.large-light.how-different {
	left: -331px;
	top: auto;
	bottom: -526px;
	border-color: hsla(0, 0%, 100%, 0.55);
	opacity: 0.06;
}

.bg-circle.large-light.bottom-right {
	left: auto;
	top: 340px;
	right: -373px;
}

.bg-circle.large-light.in-header {
	left: auto;
	top: -125px;
	right: -96px;
	border-color: hsla(0, 0%, 100%, 0.85);
	opacity: 0.05;
}

.bg-circle.large-light.dark {
	border-color: rgba(7, 38, 93, 0.05);
}

.bg-circle.company-1 {
	left: auto;
	top: 510px;
	right: -125px;
}

.bg-circle.faq-1 {
	left: auto;
	top: 264px;
	right: -95px;
}

.bg-circle.how-it-works {
	left: auto;
	top: 376px;
	right: -125px;
}

.bg-circle.all-features {
	left: -197px;
	top: 300px;
}

.bg-circle.all-features.right-version {
	left: auto;
	top: 300px;
	right: -197px;
}

.bg-circle.platform-intro-1 {
	top: -62px;
	border-color: #e8ab2d;
}

.bg-circle.platform-intro-1.pricing-1 {
	left: 20px;
	top: -40px;
}

.bg-circle.about-right {
	left: auto;
	top: auto;
	right: -155px;
	bottom: 148px;
}

.bg-circle.subpage-top-1 {
	left: 3px;
	top: -180px;
	width: 250px;
	height: 250px;
	border-width: 30px;
}

.bg-circle.subpage-bottom-1 {
	left: auto;
	top: -150px;
	right: -13px;
}

.bg-circle.security-bottom-right {
	left: auto;
	top: auto;
	right: -57px;
	bottom: 298px;
	border-color: #e8ab2d;
}

.bg-circle.different-1 {
	left: -125px;
	top: -20px;
}

.bg-circle.security-bottom-large {
	left: auto;
	top: auto;
	right: -267px;
	bottom: -150px;
	width: 350px;
	height: 350px;
	border-width: 70px;
	border-color: rgba(152, 168, 195, 0.11);
}

.breadcrumb-menu .md-drppicker .btn {
	width: 98%;
}

.breadcrumb-menu .md-drppicker .ranges ul li {
	width: 100%;
	display: inline-block;
}

.datepicker-form-div .md-drppicker .btn {
	width: 98%;
}

.dark_grey {
	color: rgb(47, 79, 79);
}

/* scg icons */
.kt-svg-icon {
	height: 23px;
	width: 23px;
}

.kt-svg-icon g [fill] {
	fill: #5d78ff;
}

.kt-svg-icon.kt-svg-icon--brand g [fill] {
	fill: #5d78ff !important;
}

.kt-svg-icon.kt-svg-icon--light g [fill] {
	fill: #ffffff !important;
}

.kt-svg-icon.kt-svg-icon--dark g [fill] {
	fill: #282a3c !important;
}

.kt-svg-icon.kt-svg-icon--primary g [fill] {
	fill: #5867dd !important;
}

.kt-svg-icon.kt-svg-icon--success g [fill] {
	fill: #0abb87 !important;
}

.kt-svg-icon.kt-svg-icon--info g [fill] {
	fill: #5578eb !important;
}

.kt-svg-icon.kt-svg-icon--warning g [fill] {
	fill: #ffb822 !important;
}

.kt-svg-icon.kt-svg-icon--danger g [fill] {
	fill: #fd397a !important;
}

.kt-svg-icon.kt-svg-icon--sm {
	height: 20px;
	width: 20px;
}

.kt-svg-icon.kt-svg-icon--md {
	height: 30px;
	width: 30px;
}

.kt-svg-icon.kt-svg-icon--lg {
	height: 40px;
	width: 40px;
}

.kt-svg-icon.kt-svg-icon--xl {
	height: 50px;
	width: 50px;
}

.green-snackbar {
	background-color: #d5f1de;
	color: #18603a;
	border-color: #cdedd8;
}

.red-snackbar {
	background-color: #fee2e1;
	color: #813838;
	border-color: #fdd6d6;
}

@media screen and (max-width: 375px) {
	.main .container-fluid {
		padding: 0 15px;
	}
}

.hr-white {
	margin: 0 0 5px;
	height: 1px;
	background: #fff;
}

//.cdk-overlay-container {
//	z-index: 2000;
//}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

@import './ebs-table.scss';

// split to separate ebs-layout file
.ebs-content {
	& .mat-tab-body-wrapper {
		height: 100%;
	}
}

.zesty-tabs {
	height: 100%;
	overflow: hidden;
}

.invisible-tabs {
	> .mat-tab-header {
		display: none;
	}
}

.zesty-content {
	& .mat-tab-body-wrapper {
		height: 100%;
	}
}

.mat-tooltip {
	font-size: 14px;
}

.external-link {
	display: flex;
	align-items: center;

	&__value {
		position: relative;
		padding-left: 20px;
		cursor: pointer;
		color: $zesty-title-black;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 3px;
			transform: translateY(-50%);
			width: 15px;
			height: 15px;
			background: url('/assets/icons/external-link-icon.svg') no-repeat center / contain;
		}

		&:hover {
			text-decoration: underline;
			color: $zesty-title-black;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 5px;
		width: 15px;
		height: 15px;
		background: url('/assets/icons/attention-icon.svg') no-repeat center / contain;
	}
}
