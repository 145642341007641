@import 'variables';
@import 'mixins/font-props';

.no-padding-dialog .mat-dialog-container {
	padding: 0;
	overflow: hidden;
	border-radius: 15px;
}

.mat-dialog-standard-title .mat-dialog-title {
	flex-shrink: 0;
	padding: 25px;
	background: $cv-dark;
	@include font-props(true, null, null, null, $zesty-white);
}

.mat-dialog-standard-content .mat-dialog-content {
	margin: 0;
}

.mat-dialog-standard-actions .mat-dialog-actions {
	padding: 25px;
	background: #fafafa;
	margin: 0;
}
