@import 'variables';

/// @param {boolean (unitless)} $isHeader
/// @param {integer (optional & unitless)} $size
/// @param {boolean (default: true while $size is specified)} $isPx
/// @param {integer (optional & unitless)} $weight
/// @param { color value (optional & unitless)} $color
@mixin font-props($isHeader, $size: null, $isPx: null, $weight: null, $color: null) {
	@if ($isHeader) {
		font-family: $header-font-family;
		letter-spacing: 1px;
	} @else {
		font-family: $text-font-family;
	}

	@if ($size) {
		@if ($isPx) {
			font-size: $size + px;
		} @else {
			font-size: $size + rem;
		}
	}

	@if ($weight) {
		font-weight: $weight;
	}

	@if ($color) {
		color: $color;
	}
}
