@mixin tooltip-top-triangle {
	&:after {
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-color: #000 transparent transparent transparent;
	}
}

@mixin tooltip-bottom-triangle {
	&:after {
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-color: transparent transparent #000 transparent;
	}
}

@mixin tooltip-left-triangle {
	&:after {
		top: 50%;
		left: 100%;
		margin-top: -5px;
		border-width: 5px;
		border-color: transparent transparent transparent #000;
	}
}

@mixin tooltip-right-triangle {
	&:after {
		top: 50%;
		right: 100%;
		margin-top: -5px;
		border-width: 5px;
		border-color: transparent #000 transparent transparent;
	}
}

@mixin tooltip-base {
	padding: 7px 12px 9px;
	width: max-content;
	max-width: 340px;
	max-height: 600px;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	color: #fff;
	background: #000;
	z-index: 10010;
	opacity: 0;
	text-overflow: ellipsis;
	word-break: break-word;

	&:after {
		content: '';
		position: absolute;
		border-style: solid;
	}

	&.single-line {
		word-break: normal;
	}

	&-show {
		opacity: 1;
	}

	&-top {
		@include tooltip-top-triangle;
	}

	&-bottom {
		@include tooltip-bottom-triangle;
	}

	&-left {
		@include tooltip-left-triangle;
	}

	&-right {
		@include tooltip-right-triangle;
	}

	&.triangle-offset-top {
		&:after {
			top: var(--triangle-offset-top);
		}
	}

	&.triangle-offset-left {
		&:after {
			left: var(--triangle-offset-top);
		}
	}

	&.max-height-reached {
		span {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			-webkit-line-clamp: inherit;
		}
	}
}

.info-tooltip {
	position: absolute;
	white-space: pre-line;
	@include tooltip-base;
}

.template-tooltip {
	@include tooltip-base;
	opacity: 1;
}

.top,
.top-left,
.top-center,
.top-right {
	.template-tooltip {
		@include tooltip-top-triangle;

		&.triangle-offset {
			&:after {
				left: var(--triangle-offset);
			}
		}
	}
}

.bottom,
.bottom-left,
.bottom-center,
.bottom-right {
	.template-tooltip {
		@include tooltip-bottom-triangle;

		&.triangle-offset {
			&:after {
				left: var(--triangle-offset);
			}
		}
	}
}

.left,
.left-top,
.left-center,
.left-bottom {
	.template-tooltip {
		@include tooltip-left-triangle;

		&.triangle-offset {
			&:after {
				top: var(--triangle-offset);
			}
		}
	}
}

.right,
.right-top,
.right-center,
.right-bottom {
	.template-tooltip {
		@include tooltip-right-triangle;

		&.triangle-offset {
			&:after {
				top: var(--triangle-offset);
			}
		}
	}
}
