@import 'constants/sizes';
@import 'constants/typography';
@import 'variables';

$info-icon-size: $spacer * 5; // 20

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.separator {
	display: block;
	margin: 2px 16px;
	border-right: 1px solid $zesty-separator-gray;
}

.info-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	height: $info-icon-size;
	width: $info-icon-size;
	cursor: pointer;

	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: $font-weight-bold;
	font-size: $font-size-md;
	line-height: $line-height-md;
	color: $zesty-blue-text;

	opacity: 0.6;
	border: none;
	border-radius: 50%;
	background: $zesty-blue-background;
	transition: opacity 0.3s ease;

	&:hover {
		opacity: 1;
	}
}
