.odometer.odometer-auto-theme {
	display: inline-block;
	vertical-align: middle;
	vertical-align: auto;
	zoom: 1;
	display: inline;
	position: relative;
	-moz-border-radius: 0.15em;
	-webkit-border-radius: 0.15em;
	border-radius: 0.15em;
	background-color: #f0f8ff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 100;
	padding: 0 0.12em;
	line-height: 1.2em;
	font-size: 1.2em;
	background-size: 16px 16px;
	.odometer-digit {
		display: inline-block;
		vertical-align: middle;
		vertical-align: auto;
		zoom: 1;
		display: inline;
		position: relative;
		-moz-border-radius: 0.1em;
		-webkit-border-radius: 0.1em;
		border-radius: 0.1em;
		color: #648baf;
		.odometer-digit-spacer {
			display: inline-block;
			vertical-align: middle;
			vertical-align: auto;
			zoom: 1;
			display: inline;
			visibility: hidden;
		}
		.odometer-digit-inner {
			text-align: left;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
			left: 0.03em;
		}
		.odometer-ribbon {
			display: block;
		}
		.odometer-ribbon-inner {
			display: block;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}
		.odometer-value {
			display: block;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
		}
		.odometer-value.odometer-last-value {
			position: absolute;
		}
	}
}
.odometer.odometer-theme-plaza {
	display: inline-block;
	vertical-align: middle;
	vertical-align: auto;
	zoom: 1;
	display: inline;
	position: relative;
	-moz-border-radius: 0.15em;
	-webkit-border-radius: 0.15em;
	border-radius: 0.15em;
	background-color: #f0f8ff;
	font-family: 'Helvetica Neue', sans-serif;
	font-weight: 100;
	padding: 0 0.12em;
	line-height: 31px;
	font-size: 31px;
	background-size: 16px 16px;
	.odometer-digit {
		display: inline-block;
		vertical-align: middle;
		vertical-align: auto;
		zoom: 1;
		display: inline;
		position: relative;
		-moz-border-radius: 0.1em;
		-webkit-border-radius: 0.1em;
		border-radius: 0.1em;
		color: #648baf;
		.odometer-digit-spacer {
			display: inline-block;
			vertical-align: middle;
			vertical-align: auto;
			zoom: 1;
			display: inline;
			visibility: hidden;
		}
		.odometer-digit-inner {
			text-align: left;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
			left: 0.03em;
		}
		.odometer-ribbon {
			display: block;
		}
		.odometer-ribbon-inner {
			display: block;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}
		.odometer-value {
			display: block;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
		}
		.odometer-value.odometer-last-value {
			position: absolute;
		}
	}
}
.odometer.odometer-auto-theme.odometer-animating-up {
	.odometer-ribbon-inner {
		-webkit-transition: -webkit-transform 2s;
		-moz-transition: -moz-transform 2s;
		-ms-transition: -ms-transform 2s;
		-o-transition: -o-transform 2s;
		transition: transform 2s;
	}
}
.odometer.odometer-theme-plaza.odometer-animating-up {
	.odometer-ribbon-inner {
		-webkit-transition: -webkit-transform 2s;
		-moz-transition: -moz-transform 2s;
		-ms-transition: -ms-transform 2s;
		-o-transition: -o-transform 2s;
		transition: transform 2s;
	}
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating {
	.odometer-ribbon-inner {
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
	}
}
.odometer.odometer-theme-plaza.odometer-animating-up.odometer-animating {
	.odometer-ribbon-inner {
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
	}
}
.odometer.odometer-auto-theme.odometer-animating-down {
	.odometer-ribbon-inner {
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
	}
}
.odometer.odometer-theme-plaza.odometer-animating-down {
	.odometer-ribbon-inner {
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
	}
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating {
	.odometer-ribbon-inner {
		-webkit-transition: -webkit-transform 2s;
		-moz-transition: -moz-transform 2s;
		-ms-transition: -ms-transform 2s;
		-o-transition: -o-transform 2s;
		transition: transform 2s;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
}
.odometer.odometer-theme-plaza.odometer-animating-down.odometer-animating {
	.odometer-ribbon-inner {
		-webkit-transition: -webkit-transform 2s;
		-moz-transition: -moz-transform 2s;
		-ms-transition: -ms-transform 2s;
		-o-transition: -o-transform 2s;
		transition: transform 2s;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
}
