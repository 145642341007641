@import 'constants/colors';

$switcher-width-with-label: 50px;
$switcher-width: 44px;
$switcher-height: 22px;
$switcher-circle: 18px;

input[type='checkbox'] {
	&.switcher {
		cursor: pointer;
		/* Reset default checkbox type styles */
		position: relative;
		z-index: unset;
		opacity: initial;

		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* Not removed via appearance */
		margin: 0;

		display: grid;
		place-content: center;
		width: $switcher-width;
		height: $switcher-height;

		font: inherit;
		color: currentColor;

		&[data-label] {
			width: $switcher-width-with-label;
		}

		&::before {
			content: '';
			position: absolute;

			z-index: 1;
			top: 50%;
			left: 0;
			transform: translate(2px, -50%);
			width: $switcher-circle;
			height: $switcher-circle;

			background-color: #fff;
			border-radius: 50%;
			box-shadow: 0 2px 4px 0 #00230b33;
			transition: all 0.3s ease;
		}
		&::after {
			content: attr(data-label);
			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 2px 2px 2px calc($switcher-circle + 4px);

			text-align: center;
			font-size: 12px;
			font-weight: 500;
			//line-height: 1;
			color: #fff;

			background-color: #c0c5ce;
			border-radius: 16px;
		}

		&:checked::before {
			left: calc(100% - #{$switcher-circle});
			transform: translate(-2px, -50%);
		}
		&:checked::after {
			padding: 2px calc($switcher-circle + 4px) 2px 2px;
			background-color: #2468ff;
		}

		&:disabled {
			color: $black-pressed;
			cursor: not-allowed;
		}
	}

	& + div {
		display: inline-flex;
		align-items: center;
		user-select: none;
	}
}
