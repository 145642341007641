@import 'constants/typography';
@import 'constants/colors';

.organization-details-toggle {
	.mat-slide-toggle-content {
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: $font-weight-normal;
		font-size: $font-size-lg;
		line-height: $line-height-lg;
		color: $zesty-dark-blue;
	}
}
