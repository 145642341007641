@import 'constants/sizes';

// "md" size by default
.icon {
	display: inline-block;
	margin: 0; // override core_ui styles
	width: $icon-md;
	height: $icon-md;
	line-height: 1;
	background: no-repeat center / contain;
}

// Sizes
.icon.icon-sm {
	width: $icon-sm;
	height: $icon-sm;
}

.icon.icon-lg {
	width: $icon-lg;
	height: $icon-lg;
}

.icon.icon-xl {
	width: $icon-xl;
	height: $icon-xl;
}

.icon.icon--arrow-down {
	background-image: url('/assets/icons/design-system/arrow-down.svg');
}
.icon.icon--arrow-right {
	background-image: url('/assets/icons/design-system/arrow-right.svg');
}

.icon.icon--thrash {
	background-image: url('/assets/icons/design-system/thrash.svg');
}

.icon.icon--edit {
	background-image: url('/assets/icons/design-system/edit.svg');
}

.icon.icon--plus {
	background-image: url('/assets/icons/design-system/plus.svg');
}

.icon.icon--auto-extend {
	background-image: url('/assets/icons/design-system/auto-extend.svg');
}

.icon.icon--zesty-disk {
	background-image: url('/assets/icons/design-system/zesty-disk.svg');
}

.icon.icon--no-policy-fs {
	background-image: url('/assets/icons/design-system/no-policy-fs.svg');
}
