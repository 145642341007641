@font-face {
	font-family: 'Messina Sans';
	src: url('messina-sans/MessinaSansWeb-Light.woff2') format('woff2');
	font-weight: 300;
}

@font-face {
	font-family: 'Messina Sans';
	src: url('messina-sans/MessinaSansWeb-Book.woff2') format('woff2');
	font-weight: 400;
}

@font-face {
	font-family: 'Messina Sans';
	src: url('messina-sans/MessinaSansWeb-Regular.woff2') format('woff2');
	font-weight: 500;
}

@font-face {
	font-family: 'Messina Sans';
	src: url('messina-sans/MessinaSansWeb-SemiBold.woff2') format('woff2');
	font-weight: 600;
}

@font-face {
	font-family: 'Messina Sans';
	src: url('messina-sans/MessinaSansWeb-Bold.woff2') format('woff2');
	font-weight: 700;
}
