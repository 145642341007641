@import 'constants/colors';
@import 'constants/sizes';

$modal-padding-x: $spacer * 16; // 64
$modal-padding-y: $spacer * 8; // 32
$border-radius: 18px;
$box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.1);

.custom-ps-dialog-container {
	max-width: 90vw !important;
}
.custom-ps-dialog-container .mat-dialog-container {
	overflow: hidden;
	padding: 0;
	max-width: 90vw;
	background-color: transparent;
	border-radius: 0;
	box-shadow: none;
}
.custom-ri-split-action-container .mat-dialog-content {
	max-height: 100%;
}

.errors-ps-dialog-container .mat-dialog-container {
	border-radius: $border-radius;
	background: $white;
	box-shadow: $box-shadow;
}
.crisis-dialog-container .mat-dialog-container {
	border-radius: $border-radius;
	background: $white;
	box-shadow: $box-shadow;
}

.learn_unused_resource_container .mat-dialog-container {
	border-radius: $border-radius;
	background: $white;
	box-shadow: $box-shadow;
}

.savings-plan-widget .mat-dialog-container {
	padding: $modal-padding-y $modal-padding-x;
	border-radius: $border-radius;
	background-color: #fff;
	box-shadow: $box-shadow;
}

.modal-xl {
	max-width: 90%;
}

.modal-600 {
	width: 600px;
	max-width: 90%;
}
