@import 'constants/typography';
@import 'constants/colors';

.custom-ps-dialog-container .animated-value .odometer {
	font-family: 'Open Sans', sans-serif !important;
	font-style: normal;

	font-weight: $font-weight-bold;
	font-size: 75px;
	line-height: 100px;
	color: $zesty-dark-blue;
}
