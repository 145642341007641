@import 'variables';
@import 'mixins/font-props';

.timepicker {
	.timepicker__header {
		background-color: $cv-dark !important;
	}

	.clock-face__number > span.active {
		background-color: $cv-dark !important;
	}

	.timepicker__actions {
		div:nth-child(1) {
			min-width: 60px;
			min-height: 36px;
			padding: 0 20px;
			color: rgba($zesty-white, 0.54);
			background: transparent;
			border-color: transparent;
			border-radius: 8px;
			transition: all 0.2s ease;

			&:hover {
				background: rgba(0, 0, 0, 0.06);
			}

			&:active {
				background: rgba(0, 0, 0, 0.12);
			}

			&:focus {
				outline: none;
				box-shadow: 0 0 0 2px #1a73e8 inset, 0 0 0 4px rgba(255, 255, 255, 0.6) inset;
			}

			span {
				all: unset !important;
				color: rgba(0, 0, 0, 0.54) !important;
			}
		}

		div:nth-child(2) {
			min-height: 36px;
			padding: 0 20px;
			color: #fff;
			background: $cv-accent;
			border-color: transparent;
			border-radius: 5px;
			transition: all 0.2s ease;
			@include font-props(false, null, null, null, #fff);

			&:hover {
				background: $cv-accent-hover;
			}

			&:active {
				background: $cv-accent-hover;
			}

			&:disabled {
				pointer-events: none;
				opacity: 0.4;
			}

			&:focus {
				outline: none;
				box-shadow: 0 0 0 2px #1a73e8 inset, 0 0 0 4px rgba(255, 255, 255, 0.6) inset;
			}

			span {
				all: unset !important;
				color: $zesty-white !important;
			}
		}
	}
}
