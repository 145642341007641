@import 'constants/typography';
@import 'constants/colors';

// "md" size by default
.btn {
	padding: 5px 15px; // +1px from box-shadow for all sides

	font-family: 'Messina Sans Trial', 'Open Sans', sans-serif;
	font-size: $font-size-md;
	font-weight: $font-weight-semibold;
	line-height: $line-height-md;

	border-radius: 8px;
	transition: all ease-out 200ms;
	&:focus {
		box-shadow: none;
	}
}
// Sizes
.btn.btn-sm {
	padding: 3px 11px;
}
.btn.btn-lg {
	padding: 7px 15px;
}
// Variants
.btn.btn-contained {
	border: none;
	&:not(:disabled) {
		color: $text-primary-inverse;
		box-shadow: 0 0 0 1px #2468ff;
		background-color: #2468ff;
		&:hover {
			box-shadow: 0 0 0 1px #508bfa;
			background-color: #508bfa;
		}
		&:active,
		&:active:focus {
			color: $text-primary-inverse;
			box-shadow: 0 0 0 2px #1c3bc7;
			background-color: #2052e5;
		}
		&:focus {
			box-shadow: 0 0 0 2px #1c3bc7;
			background-color: #508bfa;
		}
	}
	&:disabled {
		color: $text-disabled;
		box-shadow: 0 0 0 1px #ced2d9;
		background-color: #eaecef;
		opacity: 1;
	}
}

.btn.btn-contained-danger {
	border: none;

	&:not(:disabled) {
		color: $text-primary-inverse;
		box-shadow: 0 0 0 1px #bb111f;
		background-color: #bb111f;
	}

	&:disabled {
		color: $text-disabled;
		box-shadow: 0 0 0 1px #ced2d9;
		background-color: #eaecef;
		opacity: 1;
	}
}

.btn.btn-outlined {
	border: none;
	&:not(:disabled) {
		color: #2468ff;
		box-shadow: 0 0 0 1px #2468ff;
		background-color: #fff;
		&:hover {
			box-shadow: 0 0 0 2px #2468ff;
			background-color: #fff;
		}
		&:active,
		&:active:focus {
			color: #2468ff;
			box-shadow: 0 0 0 2px #2468ff;
			background-color: #d6e4ff;
		}
		&:focus {
			box-shadow: 0 0 0 2px #1c3bc7;
			background-color: #fff;
		}
	}
	&:disabled {
		color: $text-disabled;
		box-shadow: 0 0 0 1px #ced2d9;
		background-color: #eaecef;
		opacity: 1;
	}
}
.btn.btn-outlined-grey {
	border: none;
	&:not(:disabled) {
		color: #1f2633;
		box-shadow: 0 0 0 1px #dcdfe4;
		background-color: #fff;
		&:hover {
			box-shadow: 0 0 0 1px #dcdfe4;
			background-color: #f5f5f7;
		}
		&:active,
		&:active:focus {
			color: #1f2633;
			box-shadow: 0 0 0 2px #abb2be;
			background-color: #eaecef;
		}
		&:focus {
			box-shadow: 0 0 0 2px #abb2be;
			background-color: #f5f5f7;
		}
	}
	&:disabled {
		color: $text-disabled;
		box-shadow: 0 0 0 1px #ced2d9;
		background-color: #eaecef;
		opacity: 1;
	}
}
.btn.btn-text {
	border: none;
	&:not(:disabled) {
		color: #2468ff;
		box-shadow: 0 0 0 1px transparent;
		background-color: transparent;
		&:hover {
			box-shadow: 0 0 0 1px transparent;
			background-color: #f5f5f7;
		}
		&:active,
		&:active:focus {
			color: #2468ff;
			box-shadow: 0 0 0 2px transparent;
			background-color: #eaecef;
		}
		&:focus {
			box-shadow: 0 0 0 2px #1c3bc7;
			background-color: #f5f5f7;
		}
	}
	&:disabled {
		color: $text-disabled;
		box-shadow: 0 0 0 1px transparent;
		background-color: transparent;
		opacity: 1;
	}
}
