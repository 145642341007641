@import 'variables';
@import 'mixins/font-props';

.zesty__table {
	width: 100%;
	height: 100%;
	font-size: 14px;
	& .visible {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	& .empty-row {
		padding: 15px;
		@include font-props(false, 15, true, null, $cv-dark);
	}
	& datatable-header {
		margin-bottom: 10px;
		padding: 14px 0;
		line-height: 20;
		background: #fff;
		border-radius: 5px;
		@include font-props(false, 12, true, 500, #858585);
	}
	& datatable-header-cell {
		padding: 0 10px;
		& + datatable-header-cell {
			//border-left: 1px solid #ddd;
		}
		& .resize-handle {
			padding: 0 !important;
			width: 1px !important;
			background: #dddddd;
		}
		&:hover {
			& .resize-handle {
				visibility: visible !important;
			}
		}
	}
	& datatable-row-wrapper {
		&:first-child {
			& datatable-body-row {
				border-top: 0;
			}
		}
	}
	& datatable-body-row {
		border-top: 1px solid #ddd;
	}
	& datatable-body-cell {
		position: relative;
		display: flex !important;
		align-items: center;
		padding: 0 5px;
	}
	& datatable-body {
		flex-grow: 1;
	}
	& datatable-footer {
		margin-top: 70px;
		padding: 5px 15px;
		font-weight: 500;
		color: #858585;
		background: #fff;
		border-radius: 5px;
		.page-count {
			@include font-props(false);
		}
	}
	& .pager {
		& li {
			vertical-align: middle;
			&.disabled a {
				cursor: not-allowed;
				color: rgba(62, 94, 253, 0.26) !important;
				background-color: transparent !important;
			}
			&.active a {
				color: #fff;
				background-color: rgb(62, 94, 253);
				font-weight: bold;
				&:hover {
					background-color: #1765cc;
				}
			}
			& a {
				height: 22px;
				min-width: 24px;
				line-height: 22px;
				padding: 0 6px;
				border-radius: 3px;
				margin: 6px 3px;
				text-align: center;
				color: rgb(62, 94, 253);
				text-decoration: none;
				vertical-align: bottom;
				&:hover {
					background-color: #e8edf2;
				}
				& i {
					font-size: 20px;
					line-height: 20px;
					padding: 0 3px;
				}
			}
		}
	}
	& .datatable-row-detail {
		margin-bottom: 15px;
		border-radius: 5px;
	}
	& .cell-skeleton {
		display: none;
		position: absolute;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #f6f7f9;

		&__line {
			position: absolute;
			display: block;
			left: 0;
			width: 70%;
			height: 20px;
			border-radius: 5px;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)),
				#eff1f6;
			background-size: 200px 100%;
			animation: 2s cubic-bezier(0.4, 0, 0.2, 1) infinite progress;
			opacity: 0.7;
		}
	}
	& .expand-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		color: $cv-dark;
		border-radius: 5px;
		border: 2px solid transparent;
		background: transparent;
		transition: all 0.2s ease;
		&:hover {
			background: #fff;
		}
		&:focus {
			outline: none;
			border-color: $cv-accent;
		}
		&.expanded {
			background: $cv-accent;
			color: #fff;
		}
	}
	& .migrate-disk {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		padding: 0;
		color: $cv-dark;
		border-radius: 5px;
		border: 2px solid transparent;
		background: url('/assets/icons/zesty-disk/server-02.svg') no-repeat center / 20px, transparent;
		transition: all 0.2s ease;
		&:hover {
			background-color: #fff;
		}
		&:focus {
			outline: none;
			border-color: $cv-accent;
		}
		&:disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
	}
	& .change-policy {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		padding: 0;
		color: $cv-dark;
		border-radius: 5px;
		border: 2px solid transparent;
		background: url('/assets/icons/zesty-disk/change-01.svg') no-repeat center / 20px, transparent;
		transition: all 0.2s ease;
		&:hover {
			background-color: #fff;
		}
		&:focus {
			outline: none;
			border-color: $cv-accent;
		}
		&:disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
	}

	& .row-center {
		justify-content: center;
		text-align: center;
	}
	& .row-right {
		justify-content: flex-end;
		text-align: right;
	}
	& .row-visible {
		overflow: visible !important;
	}
	& .cell-options {
		position: relative;
	}

	& .overflow-auto {
		overflow: auto;
	}

	&.is-loading {
		& .cell-skeleton {
			display: flex;
		}
	}
	& .row-disabled {
		opacity: 0.6;
		background: #ebebeb;
	}
}

@media all and (max-width: 991px) {
	.zesty__table {
		& datatable-row-wrapper {
			margin-right: 0;
		}
	}
}

@keyframes progress {
	0% {
		background-position: -200px 0;
	}
	100% {
		background-position: calc(200px + 100%) 0;
	}
}
