$cv-accent: #3e5efd;
$cv-accent-hover: #1765cc;
$cv-dark: #001f38;
$cv-info: #ddd;
$cv-info-text-color: rgba(0, 0, 0, 0.54);
$cv-success: #4dbd74;
$cv-success-disabled: #c0f298;
$cv-success-hover: lighten($cv-success, 10%);
$cv-warning: #ffc107;
$cv-danger: #fd397a;
$cv-danger-hover: #fd1361;
$cv-small-button-color: #53565a;
$cv-small-button-color-hover: #d6d6d6;

$zesty-background: #e8edf2;
$zesty-blue-gray: #73818f;
$zesty-pearl: #eeeeee;
$zesty-white: #ffffff;
$zesty-grey: #e6dad8;
$zesty-blue: #184aa6;
$zesty-dark: #19212b;
$zesty-dark-hover: #344573;
$zesty-darker-hover: #26304a;
$zesty-red: #e8543b;
$zesty-restart: #bb111f;
$zesty-error-red: #f44336;
$zesty-yellow: #ffcc33;
$zesty-black: #000;
$zesty-laguna-blue: #426fda;
$zesty-slate-blue: #8f96b5;
$zesty-error-text: #f44336;
$zesty-light-blue: #e3e9f4;
$zesty-separator-gray: #e2e2e2;
$zesty-border-color: #e4e7ea;

$zesty-pale-goldenrod: #fff3ce;
$zesty-table-row-hover: #e7ecf1;
$zesty-greyed-out: #848484;
$zesty-red-light: #ffd2ca;
$zesty-dark-grey: rgb(47, 79, 79);
$dark-grey: #53565a;
$border-color: #d0d0ce;
$zesty-title-dark-grey: #2f504f;
$zesty-light-black: #3f3f3f;
$zesty-lighter-black: #c3c1c1;
$zesty-title-black: #23282c;
$zesty-blue-background: rgba(93, 120, 255, 0.3);
$zesty-blue-text: #5d78ff;

$kpi-color: #648baf;
$kpi-description-color: #2f4f4f;

$header-height: 65px;
$footer-height: 35px;
$empty-table-error-height: 63px;
$icon-width: 20px;
$icon-height: 20px;
$number-circle-size: 25px;
$flex-gap-10: 10px;
// new style for potential saving modal
$hover-gray: #f6f7f9;
$zesty-light-greenish-yellow: #0dd138;
$zesty-green-yellow: #1ba83a;
$zesty-absolute-black: #000000;
$zesty-black-text: #0d1630;
$zesty-border-grey: rgba(0, 0, 0, 0.15);
$zesty-cancel-button-background: #fbfdff;
$zesty-mid-gray: #747474;
$zest-light-azure: #2f99e7;
$zesty-white-smoke: #fbfbfb;
$zesty-steel-blue: #2e7eb7;
$zesty-vivid-red: #de0d25;
$header-font-family: 'Poppins', sans-serif;
$text-font-family: 'Open Sans', sans-serif;
$unused-resource-grey: #9e9e9e;
$unused-resource-blue: #2b7edf;
