.modal-open .modal {
	background-color: rgba(0, 0, 0, 0.1);
}

.modal-dialog .modal-content {
	overflow: hidden;
	//background-color: transparent;
	border-radius: 0.5rem;
}

.ebs-layout {
	.ebs-tabs {
		height: 100%;
		overflow: hidden;
	}

	.mat-tab-label {
		overflow: visible;
	}
}
