//FONT WEIGHT
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

//FONT SIZE
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 24px;
$font-size-xxl: 36px;

$font-size-base: $font-size-md;

//LINE HEIGHT

$line-height-sm: 16px;
$line-height-md: 20px;
$line-height-lg: 24px;
$line-height-xl: 36px;
$line-height-xxl: 40px;

$line-height-base: $line-height-md;
