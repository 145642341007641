@import 'constants/index';

.link-zesty {
	font-weight: $font-weight-normal;
	font-size: $font-size-md;
	line-height: $line-height-md;
	color: $blue;
	text-decoration: none;

	&:hover {
		color: $accent-light-blue;
	}

	&:active {
		color: $black-pressed;
	}

	&--external {
		font-weight: $font-weight-bold;
		font-size: $font-size-md;
		line-height: $line-height-md;
		color: $zesty-accent;
		text-decoration: underline;

		&:hover {
			color: $accent-light-blue;
		}

		&:focus {
			outline: none;
			color: $accent-light-blue;
		}
	}
}

.title {
	font-family: 'Messina Sans', 'Open Sans', sans-serif;
	font-size: $font-size-xxl;
	font-style: normal;
	font-weight: $font-weight-medium;
	line-height: $line-height-xxl;
}
