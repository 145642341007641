@import 'node_modules/bootstrap/scss/functions';

$spacer: 4px;
$spacers: (
	0: 0,
	1: $spacer,
	2: $spacer * 2,
	3: $spacer * 3,
	4: $spacer * 4,
	5: $spacer * 5,
	6: $spacer * 6,
	7: $spacer * 7,
	8: $spacer * 8,
	9: $spacer * 9,
	10: $spacer * 10,
	11: $spacer * 11,
	12: $spacer * 12,
	14: $spacer * 14,
	16: $spacer * 16,
);

$negative-spacers: negativify-map($spacers);

$sizes: (
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto,
);

$kpi-card-min-width: 250px;
$kpi-card-gap: $spacer * 4;

$spinner-size: $spacer * 8;

$grid-full-text-search-width: 250px;

$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;
$border-radius-xl: 16px;

$icon-sm: 12px;
$icon-md: 14px;
$icon-lg: 16px;
$icon-xl: 20px;
