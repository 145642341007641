.bs-gray-tooltip {
	.tooltip-inner {
		background-color: rgba(0, 0, 0, 0.6);
	}

	.bs-tooltip-auto[x-placement^='top'] .arrow::before,
	.bs-tooltip-top .arrow::before {
		border-top-color: rgba(0, 0, 0, 0.6);
	}
}
