$blue: #3f51b5;
$accent-blue: #4350af;
$accent-light-blue: #3e5efd;
$blue-border: #2468ff;
$gray: #858585;
$grey: #e5e5e5;
$light-gray: #d0d0ce;
$grey-blue: #0233592b;
$light-gray-white: #f6f7f9;
$light-blue-white: #e8edf2;
$black: #000000;
$black-pressed: #333333;
$white: #ffffff;
$green-dark: #26890d;
$green-dark-hover: #20730b;

//text
$gray-text-color: #53565a;
$text-color-base: $black;
$gray-dark-text-color: #23282c;

$zesty-dark-blue: #0d1630;
$zesty-grey-reworked: #747474;
$zesty-grey-hover: #fbfbfb;
$zesty-accent: #455bff;
$zesty-light-blue: #2f99e7;
$zesty-light-blue-hover: #2e7eb7;
$zesty-green: #0dd138;
$zesty-green-hover: #1ba83a;

$zesty-divider: #d6d3d3;
$zesty-light: #fbfdff;
$zesty-light-yellow: #fafbf4;
$zest-border: rgba(0, 0, 0, 0.15);

$neutral_9: #434343;
$geek_blue_500: #2f54eb;
$geek_blue_700: #10239e;
$disable: rgba(0, 0, 0, 0.25);

// NEW COLOR SYSTEM
//text
$text-primary: #1f2633;
$text-secondary: #5c677a;
$text-disabled: #969fae;
$text-link: #2468ff;
$text-error: #bb111f;
$text-primary-inverse: #fff;
$icon-following-text: #969fae;
// dividers
$border-divider: #dcdfe4;
// surface
$surface-page: #f5f5f7;
$surface-widgets: #fff;

$colors: (
	grey-100: #d9d9d9,
	error: $text-error,
	white: $white,
	black: $black,
	primary: $text-primary,
	secondary: $text-secondary,
	link: $text-link,
	disabled: $text-disabled,
	surface-page: $surface-page,
	surface-widgets: $surface-widgets,
);
