.full-text-tooltip {
	position: absolute;
	padding: 7px 12px 9px;
	max-width: 430px;
	word-break: break-word;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	color: #fff;
	background: #000;
	z-index: 1010;
	opacity: 0;
	&:after {
		content: '';
		position: absolute;
		border-style: solid;
	}

	&-show {
		opacity: 1;
	}

	&-top {
		&:after {
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-color: #000 transparent transparent transparent;
		}
	}
	&-bottom {
		&:after {
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-color: transparent transparent #000 transparent;
		}
	}
	&-left {
		&:after {
			top: 50%;
			left: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-color: transparent transparent transparent #000;
		}
	}
	&-right {
		&:after {
			top: 50%;
			right: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-color: transparent #000 transparent transparent;
		}
	}
}
