@font-face {
	font-family: 'Poppins-Bold';
	font-style: normal;
	src: url('../fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-ExtraLight';
	font-style: normal;
	src: url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Light';
	font-style: normal;
	src: url('../fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-LightItal';
	font-style: normal;
	src: url('../fonts/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Medium';
	font-style: normal;
	src: url('../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto-Medium';
	font-style: normal;
	src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v80/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}
